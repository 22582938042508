import React, { useState } from "react";
import "../screens/home/home.css";
import cannada from "../assets/cannada.png";
import india from "../assets/india.png";
import poly from "../assets/Polygon1.png";

const Input = ({ label, value, onChange, errorMsg, inputmode, type }) => {
  const fruits = [
    {
      index: 0,
      icon: india,
      code: "+91",
    },
    // {
    //   index: 1,
    //   icon: cannada,
    //   code: "+1",
    // },
    // {
    //   index: 2,
    //   icon: india,
    //   code: "+91",
    // },
    // Add more fruits here
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFruit, setSelectedFruit] = useState(
    fruits.find((fruit) => fruit.code === "+91")
  );

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const trimmedInput = inputValue.trim();
    const countryCode = selectedFruit.code;
    // Check if the input value starts with the country code
    if (trimmedInput.startsWith(countryCode)) {
      const number = trimmedInput.slice(countryCode.length).trim();
      onChange(number);
    } else {
      // If the input value doesn't start with the country code, update the input value to include the country code
      e.target.value = `${countryCode} ${value}`;
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectFruit = (fruit) => {
    setSelectedFruit(fruit);
    setIsOpen(false);
  };
  const isMobileNumber = label === "Mobile Number*";

  if (isMobileNumber) {
    return (
      <div className="user-details">
        <div className="user-text">{label}</div>
        <div className="mobile-num-container">
          <div className="input-container1">
            <div className="dropdown">
              <div
                className={`selected-option ${isOpen ? "open" : ""}`}
                onClick={toggleDropdown}
              >
                <span>
                  {selectedFruit ? (
                    <img
                      src={selectedFruit.icon}
                      alt={`Fruit ${selectedFruit.index}`}
                    />
                  ) : (
                    <img src={india} alt="India" />
                  )}
                </span>
                <span className="arrow">
                  <img src={poly} />
                </span>
              </div>
              {isOpen && (
                <div className="options">
                  {fruits.map((fruit) => (
                    <div
                      key={fruit.index}
                      className="option"
                      onClick={() => selectFruit(fruit)}
                    >
                      <img src={fruit.icon} alt={`Fruit ${fruit.index}`} />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <input
              type={type}
              className="user-input1"
              value={`${selectedFruit.code} ${value}`}
              onChange={handleInputChange}
              inputmode={inputmode ? inputmode : undefined}
            />
          </div>{" "}
          {!!errorMsg && <div className="error-msg">{errorMsg}</div>}
        </div>
      </div>
    );
  }

  return (
    <div className="user-details">
      <div className="user-text">{label}</div>
      <div className="input-container">
        <input
          type={type}
          autoFocus={false}
          className="user-input"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          inputmode={inputmode ? inputmode : undefined}
        />
        {!!errorMsg && <div className="error-msg">{errorMsg}</div>}
      </div>
    </div>
  );
};

export default Input;
