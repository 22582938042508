import React, { useState } from "react";
import laptopimg from "../../../../assets/laptop_img.png";

const ThreeDCard = ({ imgSrc, title, description }) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div
        className={hover ? "threed_card_hover" : "threed_card"}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="threed_image">
          <img src={imgSrc} alt="bike gif" />
        </div>
        <div className="card_body">
          <div className="card_title">{title}</div>
          <div className="card_dscription">{description}</div>
          <div className="card_view_demo">View demo </div>
        </div>
      </div>
    </>
  );
};

export default ThreeDCard;
