// CardComponent.jsx

import React, { useState } from "react";
import Muibutton from "../../../../components/Button";

const CardComponent = ({ imgSrc, heading, body, buttonText, link }) => {
  const onBtnClick = () => {
    const newTab = window.open("about:blank", "_blank");
    newTab.location.href = link;
  };

  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={hover ? "card_box_hover" : "card_box"}
    >
      <div className="card_img">
        <img src={imgSrc} alt="dress" />
      </div>
      <div className="card_heading ">{heading}</div>
      <div className="card_body">{body}</div>
      <div className="card_bookdemo_button ">
        <Muibutton
          text={buttonText}
          BorderRadius="0.75rem 0.75rem 0rem 0rem"
          backgroundColor="#0a59b4"
          padding="0.5rem 5rem"
          onClick={onBtnClick}
        ></Muibutton>
      </div>
    </div>
  );
};

export default CardComponent;
