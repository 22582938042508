import React from "react";
import Button from "@mui/material/Button"; // Correct import statement

const Muibutton = ({
  text,
  backgroundColor,
  backgroundOnHover,
  ColorText,
  onClick,
  fontWeight,
  padding,
  BorderRadius,
  fontSize,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        backgroundColor: backgroundColor,
        color: ColorText,
        borderRadius: BorderRadius,
        width: "auto",
        padding: padding,
        fontSize: fontSize || "1rem",
        fontWeight: fontWeight,
        fontFamily: "Lato, sans-serif",
        "&:hover": {
          backgroundColor: backgroundOnHover,
          color: ColorText,
        },
      }}
    >
      {text}
    </Button>
  );
};

export default Muibutton;
