import React, { useState } from "react";

const DiscoverCard = ({ imgSrc, description }) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div className="discover_card">
        <div className="discover_img_container">
          <img src={imgSrc} alt="img" />
        </div>
        <div className="discover_description">{description}</div>
      </div>
    </>
  );
};

export default DiscoverCard;
