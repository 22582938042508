import React, { useEffect, useState } from "react";
import "./pdp.css";
import laptopimg from "../../assets/laptop_img.png";
import laptop from "../../assets/Laptop.gif";
import Footer from "../../components/footer";
import ThreeDCard from "./components/ThreedCard";
import DiscoverCard from "./components/DiscoverCard";
import zdLogo from "../../assets/Group 92.png";
import { useNavigate } from "react-router";

const PDP = () => {
  const nav = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const threeDarray = [
    {
      id: 1,
      imgSrc: laptopimg,
      title: "ASUS TUF Fx505-dt",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply",
    },
    {
      id: 2,
      imgSrc: laptopimg,
      title: "MacBook",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply",
    },
    {
      id: 3,
      imgSrc: laptopimg,
      title: "Surface-Pro",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply",
    },
    {
      id: 4,
      imgSrc: laptopimg,
      title: "MI Notebook",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply",
    },
  ];

  const discoverArray = [
    {
      id: 1,
      imgSrc: laptopimg,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply",
    },
    {
      id: 2,
      imgSrc: laptopimg,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply",
    },
    {
      id: 3,
      imgSrc: laptopimg,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Lorem Ipsum is simply",
    },
  ];
  return (
    <>
      <div className="pdp_screen_container">
        <div className="pdp_zdlogo" onClick={() => nav("/")}>
          <img src={zdLogo} alt="zdLogo" />
        </div>
        <div className="pdp_screen_header">
          REINVENTING ENTERPRISE OPERATIONS
        </div>
        <div className="pdp_gif">
          <img src={laptop} alt="bike gif" />
        </div>
      </div>

      <div className="discover_screen">
        <div className="ourthreed_header">DiSCOVER 3D MODELS</div>
        <div className="threed_card_container">
          {discoverArray.map((item, index) => {
            return (
              <DiscoverCard
                key={index}
                imgSrc={item.imgSrc}
                description={item.description}
              />
            );
          })}
        </div>
      </div>

      <div className="our_sample_screen">
        <div className="our_sample_header_container">
          <div className="our_sample_header">OUR SAMPLE</div>
          <div className="our_sample_description">
            Take a spin around the model from the comfort of your screen. Rotate
            the model a full 360 degrees to appreciate its design and form from
            every angle.
          </div>
        </div>
        <div className="iframe_container">
          <iframe
            frameborder="0"
            scrolling="no"
            src="https://zeblobstorage.blob.core.windows.net/zeblob/WebAR_3js/ModelViewer_DTC/ar.html?pid=schair&tenantcode=demo"
          />
        </div>
      </div>

      <div className="ourthreed_screen">
        <div className="ourthreed_header">OUR 3D MODELS</div>
        <div className="threed_card_container">
          {threeDarray.map((item, index) => {
            return (
              <ThreeDCard
                key={index}
                imgSrc={item.imgSrc}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </div>
      </div>

      <div className="footer_screen">
        <Footer />
      </div>
    </>
  );
};

export default PDP;
