import React from "react";
// import "./footer.css";
import "../../screens/home/home.css";
import zdlogo from "../../assets/zd-logo.png";
import location from "../../assets/location.png";
import mobile from "../../assets/mobile.png";
import inbox from "../../assets/inbox.png";
import zelogo from "../../assets/ze-logo.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-logo-container">
          <img className="footer-logo" src={zdlogo}></img>
        </div>
        <div className="contact-us-container">
          <div className="contact-us">
            <div className="footer-header">Contact us</div>
            <div
              className="footer-header"
              style={{ padding: "2rem 0 0 0", marginLeft: "2.5rem" }}
            >
              ZiniosEdge Software Technologies Pvt Ltd.
            </div>
            <div className="contact-us-items">
              <img className="contact-us-items-img" src={location}></img>
              <div className="contact-us-items-text">
                4th Floor, Gopalakrishna Complex, 45/3 Residency Road,
                Bangalore. INDIA
              </div>
            </div>
            <div className="contact-us-items">
              <img className="contact-us-items-img" src={mobile}></img>
              <div className="contact-us-items-text">+91 9591810823</div>
            </div>
            <div className="contact-us-items">
              <img className="contact-us-items-img" src={inbox}></img>
              <div className="contact-us-items-text">info@zdviewer.com</div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-text">Powered by</div>
        <img
          className="footer-bottom-img"
          src={zelogo}
          onClick={() => window.open("https://ziniosedge.com/", "_blank")}
        ></img>
      </div>
    </div>
  );
};

export default Footer;
