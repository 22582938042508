import React, { lazy, Suspense } from "react";
import loaderGifs from "../src/assets/loading2k.gif";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@fontsource/roboto-slab";
import Loader from "../src/components/loader";
import ViewDemo from "./screens/viewdemo";
import PDP from "./screens/pdp";

function App() {
  const LandingPage = lazy(() => import("./screens/home"));

  return (
    <>
      <Suspense fallback={<Loader loaderGif={loaderGifs} />}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/viewdemo" element={<ViewDemo />} />
            <Route exact path="/pdp" element={<PDP />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
